import Container from 'react-bootstrap/Container'

import Navigation from '../../Components/Navigation'
import { Banner } from '../../Components/Banner'
import Skills from '../../Components/Skills'
import Team from '../../Components/Team'
import Footer from '../../Components/Footer'
import { ContactCTA } from '../../Components/Contact'
import './style.css'

function Landing() {
    const bannerObject =
    {
        header: "aloha, i'm lexi",
        text: "i'm a full stack web developer",
        image: "./images/home.png",
        cta: true
    }

    return (
        <div className="viewPage">
            <Navigation />
            <Container className="pageContent">
                <Banner data={bannerObject} />
                <Skills />
                <div className="text-center"><ContactCTA /></div>
                <Team />
            </Container>
            <Footer />
        </div>
    )
}

export default Landing