import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Navigation from '../../Components/Navigation'
import { Banner } from '../../Components/Banner'
import CardGrid from '../../Components/CardGrid'
import { ContactCTA } from '../../Components/Contact'
import Footer from '../../Components/Footer'

import './style.css'

function Portfolio() {
    const bannerObject =
    {
        header: "dev portfolio",
        text: "i like making things",
        carousel: [
            {
                text: "Noio Pathways",
                image: "./images/noiopathways.png"
            },
            {
                text: "Darbe",
                image: "./images/darbe.png"
            }
        ],
        cta: true
    }
    const portfolioObject = [
        {
            title: "Noio Pathways",
            image: "./images/npLogo.png",
            text: "Consultant Website",
            linkPath: "https://www.noiopathways.com",
            gallery: true
        },
        {
            title: "Michael Scales",
            image: "./images/msLogo.png",
            text: "Developer Profile",
            linkPath: "https://www.michael.scalesdev.com",
            gallery: true
        },
        {
            title: "HWOPS",
            image: "./images/hwops.png",
            text: "Paddling Club Website",
            linkPath: "https://www.hwops.org/",
            gallery: true
        },
        {
            title: "Darbe",
            image: "./images/darbe.svg",
            text: "Social Media Startup",
            linkPath: "https://www.darbe.com",
            gallery: true
        },

    ]
    return (
        <div className="viewPage">
            <Navigation />
            <Container className="pageContent">
                <Row style={{ marginTop: 10 }}>
                    <div >
                        <h1 >{bannerObject.header}</h1>
                        <h4>{bannerObject.text}</h4>
                    </div>
                </Row>

                <div className="portfolioFolders section-container text-center">
                    <CardGrid data={portfolioObject} />
                </div>
                <div className="text-center">
                    <ContactCTA />
                </div>
            </Container>
            <Footer />
        </div>
    )
}

export default Portfolio