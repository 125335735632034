import Container from 'react-bootstrap/Container'
import Navigation from '../../Components/Navigation'
import { Banner } from '../../Components/Banner'
import Services from '../../Components/Services'
import { ContactCTA } from '../../Components/Contact'
import Quotes from '../../Components/Quotes'
import Footer from '../../Components/Footer'
import ComingSoon from '../ComingSoon'

import './style.css'

function ServicesPage() {
    const bannerObject =
    {
        header: "i like creating solutions",
        text: "problems don't scare me",
        image: "./images/about.jpg",
        cta: true
    }


    return (
        <div className="viewPage">
            <Navigation />
            {/* <Container className="pageContent">
                <Banner data={bannerObject} />
                <Services />
                <div className="text-center"><ContactCTA /></div>
                <Quotes />
            </Container> */}
            <ComingSoon />
            <Footer />
        </div>
    )
}

export default ServicesPage