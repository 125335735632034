import Navigation from '../../Components/Navigation'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Image from 'react-bootstrap/Image'
import Contact from '../../Components/Contact'
import Footer from '../../Components/Footer'

import './style.css'

function ContactPage() {

    return (
        <div className="viewPage">
            <Navigation />
            <Container className="pageContent">
                <Row xs={1} sm={2}>
                    <Col className="my-auto textContainerBanner">
                        <h1 >have your people<br />call my people</h1>
                    </Col>
                    <Col className="image-container">
                        <Image className="logo-image" src={'./images/logoFull.png'} fluid />
                    </Col>
                </Row>
                <div className="contentSection"><Contact /></div>
            </Container>
            <Footer />
        </div>
    )
}

export default ContactPage
