import React from 'react'
import Container from 'react-bootstrap/Container'

import { CardGridListItem } from '../CardGrid'

import './style.css'

function Skills() {
    const skillsObject = [
        {
            title: "front end",
            image: "./images/skills1.png",
            listItem: ["React.js", "React Native", "GraphQL", "Apollo Client", "Stripe", "Javascript", "typescript"]

        },
        {
            title: "back end",
            image: "./images/skills2.png",
            listItem: ["Node.js", "Express.js", "Apollo Server", "Heroku", "MongoDB", "MySQL"]

        },
        {
            title: "architecture",
            image: "./images/skills3.png",
            listItem: ["Google Serverless Architecture", "AWS SDK", "Google Cloud Storage", "Google Cloud Run", "Google Cloud SDK", "Firebase", "Bitrise CI/CD"]
        }
    ]
    return (
        <Container className="section-container" id="skills">
            <h1 className="text-center">skillz to pay the billz</h1>
            <CardGridListItem data={skillsObject} />
        </Container>
    )
}

export default Skills