import React from 'react'
import { FaHeart, FaRegCopyright } from 'react-icons/fa';

import { ContactSocialIcons } from '../Contact';
import './style.css'

function Footer() {
    return (
        <div className="footer" fixed="bottom">
            <ContactSocialIcons />
            made with <FaHeart /> by <a className="devLink" href="https://www.linkedin.com/in/lexiscales/" target="_blank" rel="noopener noreferrer">lexi scales</a><br />
            <FaRegCopyright /> 2023
        </div>
    )
}

export default Footer